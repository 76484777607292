import { Route, Routes } from "react-router-dom";
import { ProSidebarProvider } from "react-pro-sidebar";
import { ProtectedRoutes } from "./ProtectedRoutes";
import NavBar from "../Header/NavBar";
import Footer from "../Footer/Footer";
import "../../Styles/AllRouteStyle.scss";

import HomePage from "../../Views/Auth/HomePage";
import AboutUs from "../../Views/Auth/AboutUs";
import Blogs from "../../Views/Auth/Blogs";
import ProjectCreation from "../../Views/Auth/ProjectCreation";
import ContactUs from "../../Views/Auth/ContactUs";
import PrivacyPolicy from "../../Views/Auth/PrivacyPolicy";
import TermsAndCondition from "../../Views/Auth/TermsAndCondition";
import NotFound from "../../Views/Auth/NotFound";
import NameGen from "../../Views/Auth/NameGen";
import Service from "../../Views/Services/Service";
import ServicePR from "../../Views/Services/ServicePR";
import RefundPolicy from "../../Views/Auth/RefundPolicy";
import SpecificBlogs from "../../Views/Auth/blogs/SpecificBlogs";
import SpecificBlog2 from "../../Views/Auth/blogs/SpecificBlog2";
import BlogToDisplay from "../../Views/Auth/blogs/BlogToDisplay";
import CompareServices from "../../Views/Services/CompareServices";
import CompareWebServices from "../../Views/Services/CompareWebServices";
import CompareLogoServices from "../../Views/Services/LogoService/Compare";
import FirstForm from "../../Views/Auth/PressReleaseForm";
import LogoDesignForm from "../../Views/Auth/LogoDesignForm";
import ServiceLogo from "../../Views/Services/LogoService/ServiceLogo";
import GetStatedLogo from "../../Views/Services/LogoService/GetStarted/GetStartedPage"
import WebGetStartedPage from "../../Views/Services/LogoService/GetStarted/WebGetStartedPage";
import GetStartedPage from "../../Views/Services/GetStartedPage";
import BookingDetails from "../../Views/Services/BookingDetails";
import WebBookingDetails from "../../Views/Services/WebBookingDetails";
import CongratulationsPage from "../../Views/Services/CongratulationsPage";
import ToolsPage from "../../Views/Tools/ToolsPage";
import PDF_Viewer from "../../Views/Resources/PDF_Viewer";
import Video_Library from "../../Views/Resources/Video_Library";

import PrtermsAndCondition from "../bookingDetails/PrtermsAndCondition";

import GuidanceSection from "../../Views/Auth/GuidanceSection";

import PrGenerator from "../../Views/Auth/PrGenerator";

import ServicePage from "../../Views/Auth/ServicePage";

import Resources from "../resourceSection/Resources";
import SelfServicePage from "../../Views/Auth/SelfServicePage";
import SupportPage from "../../Views/Auth/SupportPage";
import ServiceSelf from "../SelfServicePage/selfService";
import BusinessModelCanvas from "../SelfServicePage/BusinessCanvasService";
import GTM_Service from "../SelfServicePage/GTM_Service";
import PaymentFailurePage from "../../Views/Auth/PaymentFailurePage";

import comingSoon from "../comingSoon/index";
import bookingSelf from "../bookingSelf/index";
import MarketingPlanGenerator from "../../Views/Auth/MarketingPlanGenerator";

import BusinessConsultancyPage from "../SelfServicePage/BusinessConsultancyPage";
import MarketEntryPage from "../SelfServicePage/MarketEntryPage";

import TargetCustomerAnalysis from "../targetCustomerAnalysis/TargetCustomerAnalysis";
import BrandingStrategy from "../brandingStrategy/BrandingStrategy";

import SwotAnalysis from "../SelfServicePage/SwotAnalysis";
import BusinessFeasibility from "../SelfServicePage/BusinessFeasibility";
import MVPDevelopment from "../SelfServicePage/MVPDevelopment";
import CompetitorAnalysis from "../SelfServicePage/CompetitorAnalysis";

import ProductDevelopmentService from "../SelfServicePage/ProductDevelopmentService";
import WebsiteDevelopmentForm from "../../Views/Auth/WebsiteDevelopmentForm";
import GetStatedService from "../../Views/Services/LogoService/GetStarted/GetStartedServices";
 
const AuthNavigation = () => {
  const AllRoutes = () => {
    return (
      <Routes>
        {/**************************************** Guest Routes Start ****************************************/}
        {/*  */}
        {/*  */}

        <Route
          exact
          path="/"
          name="Home Page"
          element={
            <ProtectedRoutes Component={HomePage} accessRights={"guest"} />
          }
        />

        {/* <Route exact path="/home" name="Home Page" element={<ProtectedRoutes Component={HomePage} accessRights={"guest"} />} /> */}
        <Route
          exact
          path="ai-startup-tools/company-name-generator"
          name="Business Name Generator"
          element={
            <ProtectedRoutes Component={NameGen} accessRights={"guest"} />
          }
        />
        <Route
          exact
          path="/services/startup-idea-validation-service"
          name="Idea Validation"
          element={
            <ProtectedRoutes Component={ServiceSelf} accessRights={"guest"} />
          }
        />
        <Route
          exact
          path="/services/business-consultancy-service"
          name="Business Consultancy Service"
          element={
            <ProtectedRoutes
              Component={BusinessConsultancyPage}
              accessRights={"guest"}
            />
          }
        />
        <Route
          exact
          path="/services/market-entry-strategy"
          name="Market Entry Strategy"
          element={
            <ProtectedRoutes
              Component={MarketEntryPage}
              accessRights={"guest"}
            />
          }
        />

        <Route
          exact
          path="/services/target-market-analysis"
          name="TargetCustomerAnalysis"
          element={
            <ProtectedRoutes
              Component={TargetCustomerAnalysis}
              accessRights={"guest"}
            />
          }
        />
        <Route
          exact
          path="/services/business-branding-strategy-service"
          name="BusinessBrandingStrategy"
          element={
            <ProtectedRoutes
              Component={BrandingStrategy}
              accessRights={"guest"}
            />
          }
        />

        {/* Business Model Canvas Service */}
        <Route
          exact
          path="/services/business-model-canvas"
          name="Business Model Canvas"
          element={
            <ProtectedRoutes
              Component={BusinessModelCanvas}
              accessRights={"guest"}
            />
          }
        />

        {/* GTM Service */}
        <Route
          exact
          path="/services/go-to-market-strategy"
          name="GTM"
          element={
            <ProtectedRoutes Component={GTM_Service} accessRights={"guest"} />
          }
        />
        {/* swot analysis  */}
        <Route
          exact
          path="/services/swot-analysis-of-a-business"
          name="SWOT Analysis Service"
          element={
            <ProtectedRoutes Component={SwotAnalysis} accessRights={"guest"} />
          }
        />
        {/* Business Feasibility */}

        <Route
          exact
          path="/services/business-feasibility-analysis"
          name="Business Feasibility Analysis"
          element={
            <ProtectedRoutes
              Component={BusinessFeasibility}
              accessRights={"guest"}
            />
          }
        />

        {/* Product Development Service */}

        <Route
          exact
          path="/services/new-product-development-service"
          name="Product Development Service"
          element={
            <ProtectedRoutes
              Component={ProductDevelopmentService}
              accessRights={"guest"}
            />
          }
        />

        {/* MVP */}

        <Route
          exact
          path="/services/mvp-development-for-startups"
          name="MVP Development"
          element={
            <ProtectedRoutes
              Component={MVPDevelopment}
              accessRights={"guest"}
            />
          }
        />

        {/* Competitor Analysis */}

        <Route
          exact
          path="/services/competitor-analysis"
          name="Competitor Analysis"
          element={
            <ProtectedRoutes
              Component={CompetitorAnalysis}
              accessRights={"guest"}
            />
          }
        />
        {/* supportPage */}

        <Route
          exact
          path="/support"
          name="Support Page"
          element={
            <ProtectedRoutes Component={SupportPage} accessRights={"guest"} />
          }
        />

        {/* Route for pr generator */}
        <Route
          exact
          path="ai-startup-tools/ai-press-release-generator-free"
          name="Press Release Generator"
          element={
            <ProtectedRoutes Component={PrGenerator} accessRights={"guest"} />
          }
        />
        {/* Route for ai marketing plan generator tool */}
        <Route
          exact
          path="ai-startup-tools/ai-marketing-plan-generator"
          name="AI Marketing Plan Generator"
          element={
            <ProtectedRoutes
              Component={MarketingPlanGenerator}
              accessRights={"guest"}
            />
          }
        />

        <Route
          exact
          path="/booking-self/:serviceName"
          name="Booking Self"
          element={
            <ProtectedRoutes Component={bookingSelf} accessRights={"guest"} />
          }
        />

        <Route
          exact
          path="/coming-soon"
          name="Coming Soon"
          element={
            <ProtectedRoutes Component={comingSoon} accessRights={"guest"} />
          }
        />

        <Route
          exact
          path="/aboutUs"
          name="About Us"
          element={
            <ProtectedRoutes Component={AboutUs} accessRights={"guest"} />
          }
        />

        <Route
          exact
          path="/resources"
          name="Blogs"
          element={
            <ProtectedRoutes Component={Resources} accessRights={"guest"} />
          }
        />
        <Route
          exact
          path="/resources/blogs"
          name="Blogs"
          element={<ProtectedRoutes Component={Blogs} accessRights={"guest"} />}
        />

        {/* Resources PDF-Viewer code */}
        <Route
          exact
          path="/resources/market-research"
          name="Pdfviewer"
          element={
            <ProtectedRoutes Component={PDF_Viewer} accessRights={"guest"} />
          }
        />

        {/* Resources Video-Library code */}
        <Route
          exact
          path="/resources/video-library"
          name="Pdfviewer"
          element={
            <ProtectedRoutes Component={Video_Library} accessRights={"guest"} />
          }
        />

        {/* Payment Failure Page */}
        <Route
          exact
          path="/paymentFailure"
          name="Payment Failure Page"
          element={
            <ProtectedRoutes
              Component={PaymentFailurePage}
              accessRights={"guest"}
            />
          }
        />

        <Route
          exact
          path="paymentSuccess"
          name="paymentSuccess"
          element={
            <ProtectedRoutes
              Component={CongratulationsPage}
              accessRights={"guest"}
            />
          }
        />

        <Route
          exact
          path="/business-support"
          name="Blogs"
          element={
            <ProtectedRoutes
              Component={GuidanceSection}
              accessRights={"guest"}
            />
          }
        />
        <Route
          exact
          path="/services"
          name="ServicePage"
          element={
            <ProtectedRoutes Component={ServicePage} accessRights={"guest"} />
          }
        />
        <Route
          exact
          path="/contactUs"
          name="Contact Us"
          element={
            <ProtectedRoutes Component={ContactUs} accessRights={"guest"} />
          }
        />
        {/* <Route exact path="/blogs/:blogId" name="Specific Blogs" element={<ProtectedRoutes Component={SpecificBlogs} accessRights={"guest"} />} /> */}
        {/* <Route exact path="/blogs/:blogId" name="Specific Blogs" element={<ProtectedRoutes Component={BlogToDisplay} accessRights={"guest"} />} /> */}
        {/* <Route exact path="/resources/blogs/:blogId/:blogId" name="Specific Blogs" element={<ProtectedRoutes Component={SpecificBlogs} accessRights={"guest"} />} /> */}
        <Route
          exact
          path="/resources/blogs/:type/:id"
          name="Specific Blog2"
          element={
            <ProtectedRoutes Component={BlogToDisplay} accessRights={"guest"} />
          }
        />
        <Route
          exact
          path="/projectCreation"
          name="Project Creation"
          element={
            <ProtectedRoutes
              Component={ProjectCreation}
              accessRights={"guest"}
            />
          }
        />
        <Route
          exact
          path="/services/press-release-distribution"
          name="Project Creation"
          element={
            <ProtectedRoutes Component={FirstForm} accessRights={"guest"} />
          }
        />
        <Route
          exact
          path="/service/web-development-distribution"
          name="Web Development"
          element={
            <ProtectedRoutes
              Component={WebsiteDevelopmentForm}
              accessRights={"guest"}
            />
          }
        />

        <Route
          exact
          path="/service/:serviceName/quote-details/:enquiryID"
          name="Quote Details"
          element={
            <ProtectedRoutes Component={Service} accessRights={"guest"} />
          }
        />
        {/* main service page  */}
        <Route
          exact
          path="services/web-devlopement/quote-details/service-summary/"
          name="GetStarted"
          element={
            <ProtectedRoutes Component={GetStatedService} accessRights={"guest"} />
          }
        />

        {/* Logo Routes */}
        <Route
          exact
          path="/services/logo-design-distribution"
          name="Project Creation"
          element={
            <ProtectedRoutes
              Component={LogoDesignForm}
              accessRights={"guest"}
            />
          }
        />

        <Route
          exact
          path="/services/logo-design-distribution/quote-details"
          name="ServiceLogo"
          element={
            <ProtectedRoutes Component={ServiceLogo} accessRights={"guest"} />
          }
        />

      <Route
          path="/services/:serviceName/quote-details/:enquiryID/compare-plans/:bidID"
          name="Compare"
          element={
            <ProtectedRoutes
              Component={CompareWebServices}
              accessRights={"guest"}
            />
          }
        />
        
        <Route
          path="/services/logo-design-distribution/quote-details/compare-plans/:service?"
          name="Compare"
          element={
            <ProtectedRoutes
              Component={CompareLogoServices}
              accessRights={"guest"}
            />
          }
        />
        <Route
          exact
          path="services/logo-design-distribution/quote-details/service-summary/"
          name="GetStarted"
          element={
            <ProtectedRoutes Component={GetStatedLogo} accessRights={"guest"} />
          }
        />

        <Route
          exact
          path="/privacyPolicy"
          name="Privacy Policy"
          element={
            <ProtectedRoutes Component={PrivacyPolicy} accessRights={"guest"} />
          }
        />

        <Route
          exact
          path="/pr/terms&conditions"
          name="terms&conditions"
          element={
            <ProtectedRoutes
              Component={PrtermsAndCondition}
              accessRights={"guest"}
            />
          }
        />
        <Route
          exact
          path="/refund-and-cancellation-policy"
          name="Refund Policy"
          element={
            <ProtectedRoutes Component={RefundPolicy} accessRights={"guest"} />
          }
        />
        <Route
          exact
          path="/termsAndCondition"
          name="Terms & Condition"
          element={
            <ProtectedRoutes
              Component={TermsAndCondition}
              accessRights={"guest"}
            />
          }
        />

        <Route
          path="services/press-release-distribution/quote-details/compare-plans/:service?"
          name="Compare"
          element={
            <ProtectedRoutes
              Component={CompareServices}
              accessRights={"guest"}
            />
          }
        />
        <Route
          exact
          path="services/press-release-distribution/quote-details"
          name="ServicePR"
          element={
            <ProtectedRoutes Component={ServicePR} accessRights={"guest"} />
          }
        />

        {/* ToolsPage */}
        <Route
          exact
          path="/ai-startup-tools"
          name="tools"
          element={
            <ProtectedRoutes Component={ToolsPage} accessRights={"guest"} />
          }
        />
        <Route
          exact
          path="/selfService"
          name="selfService"
          element={
            <ProtectedRoutes
              Component={SelfServicePage}
              accessRights={"guest"}
            />
          }
        />

        <Route
          exact
          path="services/press-release-distribution/quote-details/service-summary/"
          name="GetStarted"
          element={
            <ProtectedRoutes
              Component={GetStartedPage}
              accessRights={"guest"}
            />
          }
        />

        <Route
          exact
          path="/services/:serviceName/quote-details/:enquiryID/getStarted/:bidID"
          name="WebGetStarted"
          element={
            <ProtectedRoutes
              Component={WebGetStartedPage}
              accessRights={"guest"}
            />
          }
        />

        <Route
          exact
          path="services/press-release-distribution/quote-details/service-summary/book-service/:id?"
          name="BookingDetails"
          element={
            <ProtectedRoutes
              Component={BookingDetails}
              accessRights={"guest"}
            />
          }
        />

        <Route
          exact
          path="/services/:serviceName/quote-details/:enquiryID/getStarted/:bidID/bookService"
          name="WebBookingDetails"
          element={
            <ProtectedRoutes
              Component={WebBookingDetails}
              accessRights={"guest"}
            />
          }
        />

      <Route
          exact
          path="/services/:serviceName/quote-details/:enquiryID/getStarted/:bidID/bookService/booking-success"
          name="Congratulation"
          element={
            <ProtectedRoutes
              Component={CongratulationsPage}
              accessRights={"guest"}
            />
          }
        />

        <Route
          exact
          path="services/press-release-distribution/quote-details/service-summary/book-service/booking-success "
          name="Congratulation"
          element={
            <ProtectedRoutes
              Component={CongratulationsPage}
              accessRights={"guest"}
            />
          }
        />

        <Route
          path="*"
          element={
            <ProtectedRoutes Component={NotFound} accessRights={"guest"} />
          }
        />
        {/*  */}
        {/*  */}
        {/**************************************** Guest Routes Close ****************************************/}

        {/**************************************** User Routes Start ****************************************/}
        {/*  */}
        {/*  */}
        {/* <Route exact path="/user/" name="User Dashboard" element={<ProtectedRoutes Component={UserDashboard} accessRights={"user"} />} /> */}

        {/*  */}
        {/*  */}
        {/**************************************** User Routes Close ****************************************/}

        {/**************************************** Admin Routes Start ****************************************/}
        {/*  */}
        {/*  */}
        {/* <Route exact path="/admin/" name="Dashboard" element={<ProtectedRoutes Component={Dashboard} accessRights={"admin"} />} /> */}

        {/*  */}
        {/*  */}
        {/**************************************** Admin Routes Close ****************************************/}
      </Routes>
    );
  };

  return (
    <ProSidebarProvider>
      <div className="mainContainer">
        {/* <NavBar/> */}
        <AllRoutes />
        {/* <Footer/> */}
      </div>
    </ProSidebarProvider>
  );
};
export default AuthNavigation;
