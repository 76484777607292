import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import bizowl_logo from "../../../../Components/getStarted/assets/bizowl_logo.png";
import call_logo from "../../../../Components/getStarted/assets/phone-icon.png";
import "../../getStartedPage.css";
import mockdata from "../mockData.json";
import "../../getStartedPage.css";
import { All_features, FAQSection, PaymentSummary } from "./GetStartedPage";
import { Button, useMediaQuery } from "@mui/material";

import { FaRegUser } from "react-icons/fa";
import { BiBriefcase } from "react-icons/bi";

import MessageImg from "../../assets/Vector (2).png";
import PhoneImg from "../../assets/Vector (3).png";
import VideoImg from "../../assets/Vector (4).png";
import Star from "../../assets/solar_star-bold.png";
import Expand from "../../assets/Vector (7).png";
import CollapseIcon from "../../assets/Colapse.png";

import PortfolioCard from "./PortfolioCard";
import ReviewCard from "./ReviewCard";

function GetStatedService() {
  const [searchParams] = useSearchParams();
  const [data, setData] = useState(mockdata);
  const [isLoading, setIsLoading] = useState(true);
  const [displayData, setDisplayData] = useState();
  const [isDetail, setIsDetail] = useState(true);
  const isMobile = useMediaQuery("(max-width:768px)");

  const id = Number(searchParams.get("id"));

  useEffect(() => {
    // console.log("id-> ", id);
    const foundData = data.find((item) =>
      item.Plans.some((plan) => plan.id === id)
    );

    if (foundData) {
      const foundPlan = foundData.Plans.find((plan) => plan.id === id);
      setDisplayData({ ...foundPlan, partnerName: foundData["Partner Name"] });
      setIsLoading(false);
    } else {
      setDisplayData(null);
    }
  }, [data, id]);

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (!displayData || displayData.length === 0) {
    return <p>No service data found.</p>;
  }

  const displayName = displayData.partnerName.substring(0, 3).toUpperCase();
  // console.log("displaydata main-> ", displayData);

  return (
    <div
      style={{
        display: "flex",
        position: "relative",
        flexDirection: "column",
        alignItems: "center",
        height: "auto",
        width: "100%",
        fontFamily: "'Cabin', sans-serif",
        backgroundColor: "#ecf5fc",
      }}
      className="md:mb-0 mb-12"
    >
      <nav
        style={{
          display: "flex",
          position: "relative",
          alignItems: "center",
          justifyContent: "space-between",
          paddingTop: "15px",
          paddingBottom: "15px",
          height: "25%",
          width: "100%",
          boxShadow: "0px 0px 3px 0px rgba(0,0,0,0.75)",
        }}
      >
        <div
          id="logo"
          style={{ height: "auto", width: "auto", marginLeft: "40px" }}
        >
          <img id="logo-text" src={bizowl_logo} style={{ width: "100px" }} />
        </div>

        <div
          id="need-help"
          style={{
            display: "flex",
            alignItems: "center",
            position: "relative",
            height: "auto",
            width: "auto",
            marginRight: "40px",
            color: "blue",
            fontWeight: "bold",
            cursor: "pointer",
          }}
        >
          <div
            className="call-logo"
            style={{
              background: "linear-gradient(to bottom, #acc3f5 0%, white 100%)",
              boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.75)",
              height: "30px",
              width: "30px",
              borderRadius: "30px",
              marginRight: "5px",
            }}
          >
            <img
              src={call_logo}
              style={{
                marginTop: "5px",
                marginLeft: "5px",
                height: "70%",
                width: "70%",
              }}
            />
          </div>
          <p style={{ marginLeft: "5px", fontWeight: "100" }}>Need Help?</p>
        </div>
      </nav>

      {isMobile && (
        <div className="w-full flex border-t-2 px-4 py-2 fixed z-30 bg-white bottom-0 justify-between ">
          <div className="text-[#455A64]">
            <p className="text-xl font-bold">₹6419/-</p>
            <p className="text-xs">GST Included</p>
          </div>
          <button className="bg-blue-600 text-white py-1 px-6">Proceed</button>
        </div>
      )}

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "70vw 30vw",
          width: "100%",
          height: "auto",
        }}
      >
        <div style={{ display: "flex", marginTop: "20px" }}>
          <div
            style={{
              display: "flex",
              border: "3px solid #007CFF",
              backgroundColor: "#cbe3fd",
              justifyContent: "center",
              alignItems: "center",
            }}
            className={`mx-6 md:mx-20 h-fit ${
              isMobile && "p-4"
            } p-12 rounded-xl md:rounded-2xl`}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                letterSpacing: "5px",
                fontWeight: "bold",
                alignItems: "center",
                border: "3px solid rgb(0, 124, 255)",
                color: "black",
                backgroundColor: "#F7FCFB",
              }}
              className={`text-sm md:text-[3rem] rounded-xl md:rounded-2xl md:tracking-[8px] ${
                isMobile && "p-3"
              } p-9`}
            >
              <p>{"WXT"}</p>
            </div>
          </div>

          <div className="text-start flex flex-col gap-3 w-7/12 text-[#455A64] tracking-wide">
            <p className="text-4xl tracking-[5px] w-full font-bold">{"WXT"}</p>
            {!isMobile && (
              <div className="w-full">
                <p>Ratings</p>
                <div className="flex">
                  {Array.from({ length: 5 }, (_, index) => (
                    <img key={index} src={Star} width={"20px"} />
                  ))}
                </div>
              </div>
            )}
            <div className="w-full flex gap-2.5">
              <div className="flex gap-1 items-center">
                <div className="p-1 w-fit rounded-[5px] bg-[#505A5F]">
                  {" "}
                  <img
                    src={MessageImg}
                    alt="MessageImg"
                    className="size-[.7rem]"
                  />
                </div>
                {!isMobile && <p>Message</p>}
              </div>
              <div className="flex gap-1 items-center">
                <div className="p-1 w-fit rounded-[5px] bg-[#505A5F]">
                  {" "}
                  <img src={PhoneImg} alt="PhoneImg" className="size-[.7rem]" />
                </div>
                {!isMobile && <p>Call</p>}
              </div>
              <div className="flex gap-1 items-center">
                <div className="p-1 w-fit rounded-[5px] bg-[#505A5F]">
                  {" "}
                  <img src={VideoImg} alt="VideoImg" className="size-[.7rem]" />
                </div>
                {!isMobile && <p>Video</p>}
              </div>
            </div>

            <div
              className={`flex gap-3 ${
                isMobile && "gap-2"
              } w-full mt-3 border-b-[1px] border-[#505A5F]`}
            >
              <div className="min-w-fit">
                <FaRegUser className="inline md:text-[23px]" />
                <Button
                  variant="text"
                  sx={{
                    padding: 0,
                    color: "#455A64",
                    textTransform: "none",
                  }}
                  onClick={() => setIsDetail(true)}
                >
                  Details
                </Button>
                {isDetail && <div className="h-0.5 bg-[#407BFF] z-10"></div>}
              </div>
              <div className="min-w-fit">
                <BiBriefcase className="inline md:text-[25px]" />
                <Button
                  variant="text"
                  sx={{
                    padding: 0,

                    color: "#455A64",
                    textTransform: "none",
                  }}
                  onClick={() => setIsDetail(false)}
                >
                  About
                </Button>
                {!isDetail && <div className="h-0.5 bg-[#407BFF]"></div>}
              </div>
            </div>
          </div>
        </div>
        {isMobile && <div></div>}
        {isDetail && !isMobile && (
          <div
            style={{
              display: "flex",
              height: "auto",
              margin: "10px",
              width: "300px",
            }}
          >
            <PaymentSummary
              displayName={displayName}
              displayData={displayData}
            />
          </div>
        )}

        {isDetail && !isMobile && (
          <div
            style={{
              position: "relative",
              height: "auto",
              margin: "10px",
              width: "95%",
              bottom: "80px",
            }}
          >
            <All_features displayData={displayData} />
          </div>
        )}
      </div>
      {!isDetail && (
        <>
          <div></div>
          <div className="flex w-full justify-start px-6 md:px-20 mt-3 gap-20">
            <div
              className="items-start hidden justify-start text-start md:flex flex-col gap-3 self-start text-[#455A64] w-[299px]"
              style={{ justifyContent: "start", alignItems: "start" }}
            >
              <div className="border-b-[1px] border-gray-500 w-full">
                <p className="w-full">Work</p>
              </div>
              <div className="w-full">
                <p>Services Offered :</p>
                <p className="font-bold mt-1">Web Development</p>
                <div className="flex justify-between items-center w-full">
                  <p className="font-bold">Digital Marketing</p>
                  <img src={Expand} alt="expand-icon" width={"10px"} />
                </div>
              </div>
              <div className="w-full">
                <div className="flex justify-between items-center w-full">
                  <p>Expertise :</p>
                  <img src={Expand} alt="expand-icon" width={"10px"} />
                </div>
                <p className="font-bold">Web Development</p>
              </div>
              <div>
                <p>Industries :</p>
              </div>
            </div>
            <div className="flex flex-col text-start gap-3 h-full">
              <p className="w-full">Basic Details</p>
              <p className="w-full">
                Experience: <b>7 Years</b>
              </p>

              <div className="flex gap-3 items-center w-full">
                <p>Portfolio</p>
                {!isMobile && <span> :</span>}
                {isMobile && (
                  <img src={CollapseIcon} alt="collapse-icon" width={"20px"} />
                )}
              </div>
              <div
                className={`flex flex-wrap w-full gap-6 ${isMobile && "gap-2"}`}
              >
                <PortfolioCard />
                <PortfolioCard />
              </div>
              <div className="flex gap-3 items-center w-full">
                <p>Reviews</p>
                {!isMobile && <span> :</span>}
                {isMobile && (
                  <img src={CollapseIcon} alt="collapse-icon" width={"20px"} />
                )}
              </div>
              <div
                className={`flex flex-wrap w-full gap-6 ${isMobile && "gap-2"}`}
              >
                <ReviewCard />
                <ReviewCard />
              </div>
            </div>
          </div>
        </>
      )}

      {isMobile && isDetail && (
        <div
          style={{
            margin: "10px",
            width: "95%",
          }}
        >
          <All_features displayData={displayData} />
        </div>
      )}
      <FAQSection />
    </div>
  );
}
export default GetStatedService;
