import './App.css';
import AuthNavigation from './Components/Navigation/AuthNavigation';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect,useState,createContext } from 'react';
import { ScrollToTop } from './Components/Extra/extraFunction';
// import { LoginModal } from './Components/Design/HomePage/Modals';
import { ReactNotifications } from 'react-notifications-component'

export const userContext=createContext()

function App() {
  const [verifiedUser,setVerifiedUser]=useState();

  const checkSession=async ()=>{
    const response=await fetch("https://backend.bizzowl.com/api/authentication/authRoute2/verifyUser",{
      credentials:"include"
    })

    const data=await response.json()

    console.log("data:",data);
    setVerifiedUser(data.user.uid);
  }
  
  useEffect(() => {
    AOS.init({duration:500});
    checkSession();
  }, [])
  
  return (
    <div className="App">
      <ReactNotifications/>
      <ScrollToTop/>
      {/* <LoginModal/> */}
      <userContext.Provider value={verifiedUser}>
        <AuthNavigation/>
      </userContext.Provider>
    </div>
  );
}

export default App;
