import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import bizowl_logo from "../../../../Components/getStarted/assets/bizowl_logo.png";
import call_logo from "../../../../Components/getStarted/assets/phone-icon.png";
import "../../getStartedPage.css";
import mockdata from "../mockData.json";
import "../../getStartedPage.css";
import { collection,where,query,getDocs } from "firebase/firestore";
import { partnerDB } from "../../../../config/firebase";
// Feature Imports Start
import calendar from "../../assets/calendar.png";
import experience from "../../assets/experience.png";
import StartDate from "../../assets/start-date.png"
import revision from "../../assets/Revision.png"
import money from "../../assets/money.png";
import communication from "../../assets/247hr-communication.png";
import service from "../../assets/service.png";
import timeline from "../../assets/refresh-time.png";
import deliverySupport from "../../assets/Deliver.png";
import Payment from "../../assets/Payment.png"
import technicalSupport from "../../assets/technical-support.png"
import list from "../../assets/list.png"
import Star from "../../assets/star.png"
import Website from "../../assets/Website.png";
import numberBlocks from "../../assets/number-blocks.png"
import UsedTech from "../../assets/robotic-hand.png"
import newFeature from "../../assets/new-features.png";
import { Navigate,useParams } from "react-router-dom";
// Feature Imports End

function WebGetStartedPage() {
  const [searchParams] = useSearchParams();
  const [data, setData] = useState(mockdata);
  const [isLoading, setIsLoading] = useState(true);
  const [displayData, setDisplayData] = useState();
  const [BiddingData, setBiddingData] = useState([]);
  const { bidID, serviceName, enquiryID } = useParams();

  // useEffect(() => {
  //   // console.log("id-> ", id);
  //   const foundData = data.find((item) =>
  //     item.Plans.some((plan) => plan.id === id)
  //   );

  //   if (foundData) {
  //     const foundPlan = foundData.Plans.find((plan) => plan.id === id);
  //     setDisplayData({ ...foundPlan, partnerName: foundData["Partner Name"] });
  //     setIsLoading(false);
  //   } else {
  //     setDisplayData(null);
  //   }
  // }, [data, id]);



  const getData = async () => {
    try {
      const collectionRef = collection(partnerDB, "biddingData");
      const enquiryQuery = query(collectionRef, where("EnquiryDetails.enquiryID", "==", enquiryID));
      const querySnapshot = await getDocs(enquiryQuery);
      if (!querySnapshot.empty) {
        const docId = querySnapshot.docs[0].id;
        const partnerCollectionRef = collection(partnerDB, `biddingData/${docId}/partners`);
        console.log("bidId:", bidID);
        const partnerQuery = query(partnerCollectionRef, where("biddingDetails.bidID", "==", bidID));
        const partnerDoc = await getDocs(partnerQuery);
        const partnerData = partnerDoc.docs[0].data();
        console.log("partnerData:",partnerData)
        setBiddingData(partnerData);
        }
        setIsLoading(false);
    } catch (error) {
      console.log(error.message);
    }
  }

  useEffect(()=>{
    getData();
  },[])

  useEffect(()=>{
    console.log("Bidding data:",BiddingData)
  },[BiddingData])

  const displayName=BiddingData?.partnerDetails?.partnerName.substring(0,3).toUpperCase();

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (!BiddingData || BiddingData.length === 0) {
    return <p>No service data found.</p>;
  }
  // console.log("displaydata main-> ", displayData);

  

  return (
    <div
      style={{
        display: "flex",
        position: "relative",
        flexDirection: "column",
        alignItems: "center",
        height: "auto",
        width: "100%",
        fontFamily: "'Cabin', sans-serif",
      }}
    >
      <nav
        style={{
          display: "flex",
          position: "relative",
          alignItems: "center",
          justifyContent: "space-between",
          paddingTop: "15px",
          paddingBottom: "15px",
          height: "25%",
          width: "100%",
          boxShadow: "0px 0px 3px 0px rgba(0,0,0,0.75)",
        }}
      >
        <div
          id="logo"
          style={{ height: "auto", width: "auto", marginLeft: "40px" }}
        >
          <img
            id="logo-text"
            src={bizowl_logo}
            style={{ height: "10px", width: "100px" }}
          />
        </div>

        <div
          id="need-help"
          style={{
            display: "flex",
            alignItems: "center",
            position: "relative",
            height: "auto",
            width: "auto",
            marginRight: "40px",
            color: "blue",
            fontWeight: "bold",
            cursor: "pointer",
          }}
        >
          <div
            className="call-logo"
            style={{
              background: "linear-gradient(to bottom, #acc3f5 0%, white 100%)",
              boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.75)",
              height: "30px",
              width: "30px",
              borderRadius: "30px",
              marginRight: "5px",
            }}
          >
            <img
              src={call_logo}
              style={{
                marginTop: "5px",
                marginLeft: "5px",
                height: "70%",
                width: "70%",
              }}
            />
          </div>
          <p style={{ marginLeft: "5px", fontWeight: "100" }}>Need Help?</p>
        </div>
      </nav>

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "70vw 30vw",
          width: "100%",
          height: "auto",
        }}
      >
        <div style={{ display: "flex", marginTop: "30px", height: "50%" }}>
          <div
            style={{
              display: "flex",
              height: "200px",
              width: "300px",
              border: "3px solid #007CFF",
              backgroundColor: "#cbe3fd",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "15px",
              marginLeft: "75px",
            }}
          >
            <div
              style={{
                display: "flex",
                height: "50%",
                width: "60%",
                justifyContent: "center",
                fontSize: "40px",
                letterSpacing: "5px",
                fontWeight: "bold",
                alignItems: "center",
                borderRadius: "15px",
                margin: "10px",
                backgroundColor: "#F7FCFB",
              }}
            >
              <p>{displayName}</p>
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            height: "auto",
            margin: "10px",
            width: "300px",
          }}
        >
          <PaymentSummary displayName={displayName} BiddingData={BiddingData} serviceName={serviceName} enquiryID={enquiryID} bidID={bidID} />
        </div>
        <div
          style={{
            position: "relative",
            height: "auto",
            margin:"10px",
            width: "95%",
            bottom: "80px",
          }}
        >
          <All_features BiddingData={BiddingData} />
        </div>
      </div>
        <FAQSection />
    </div>
  );
}

export default WebGetStartedPage;

export function PaymentSummary({ displayName, BiddingData,serviceName,enquiryID,bidID }) {
  const navigate=useNavigate();
  const handleProceed=()=>{
navigate(`/services/${serviceName}/quote-details/${enquiryID}/getStarted/${bidID}/bookService`)
  }
  return (
    <div id="summary-container">
      <div id="summary-heading">
        <p>Payment summary</p>
      </div>

      <div className="summary-items">
        <div className="summary-content">
          <div className="summary-logo px-4 py-2 rounded text-lg">{displayName}</div>
        </div>
        <div className="press-release-content">
          <div className="press-release-section">
            <p>Web Development</p>
          </div>
        </div>
        <div className="summary-content">
          <div className="summary-item1">
            <p>Total Payment</p>
          </div>
          <div className="summary-item2">
            <p>₹{parseInt(BiddingData?.biddingDetails?.price)}/-</p>
          </div>
        </div>
        <div className="summary-content">
          <div className="summary-item1">
            <p>GST (18%)</p>
          </div>
          <div className="summary-item2">
            <p>₹{((parseInt(BiddingData?.biddingDetails?.price) * 18) / 100).toFixed(2)}/-</p>
          </div>
        </div>
      </div>
      <div id="total">
        <div className="total-item1">
          <p>Payable amount</p>
        </div>
        <div className="total-item2">
          <p>
            ₹{((parseInt(BiddingData?.biddingDetails?.price) * 18) / 100 + parseInt(BiddingData?.biddingDetails?.price)).toFixed(2)}/-
          </p>
        </div>
      </div>
      <div
        id="btn-container"
        // onClick={() => navigate(`/services/press-release-distribution/quote-details/service-summary/book-service/?id=${servicesdisplayData.id}&index=${index}`)} // Original
        // onClick={() => navigate(`/services/press-release-distribution/quote-details/service-summary/book-service/?id=${servicesdisplayData.id}`)}
      >
        <button id="proceed" onClick={handleProceed}>Proceed</button>
      </div>
    </div>
  );
}

export const All_features = ({BiddingData}) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const containerStyle = {
    height: "auto",
    width: screenWidth <= 530 ? "100%" : screenWidth <= 1200 ? "80%" : "90%",
    backgroundColor: "white",
    borderRadius: "5px",
    textAlign: "justify",
    marginLeft:
      screenWidth <= 530 ? "0px" : screenWidth <= 1200 ? "10%" : "50px",
    marginTop: screenWidth <= 530 ? "5rem" : "0",
    alignSelf: screenWidth <= 700 ? "center" : "auto",
  };

  const detailSectionStyle = {
    display: screenWidth <= 530 ? "flex" : "grid",
    gridTemplateColumns: "50% 50%",
    boxShadow:
      screenWidth <= 530 ? "none" : "0 4px 2px -2px rgba(0, 0, 0, 0.2)",
    fontSize: "15px",
    rowGap: "10px",
    height: "auto",
    paddingBottom: "7px",
    width: screenWidth <= 530 ? "95%" : "98%",
    flexDirection: screenWidth <= 530 ? "column" : "row",
  };

  
  return (
    <div className="container w-90 bg-white text-black rounded ms-5 text-justify">
      <div className="d-flex flex-column">
        <h4 className="mt-3 ms-2">All Features</h4>
        
        {/* First Detail Section */}
        <div className="row w-98 mb-2 shadow-sm">
          <div className="col-md-6">
            <div className="d-flex flex-column mt-4">
              <div className="d-flex align-items-center ms-2">
                <div className="me-2" style={{ width: '20px', height: '20px' }}>
                  <img src={money} alt="money" className="img-fluid" />
                </div>
                <div>
                  <p className="text-secondary small mb-0">Price</p>
                </div>
              </div>
              <p className="fw-semibold ms-4 text-dark fs-6">₹{BiddingData?.biddingDetails?.price}</p>
            </div>
          </div>
          
          <div className="col-md-6">
            <div className="d-flex flex-column mt-4">
              <div className="d-flex align-items-center ms-2">
                <div className="me-2" style={{ width: '20px', height: '20px' }}>
                  <img src={revision} alt="calendar" className="img-fluid" />
                </div>
                <div>
                  <p className="text-secondary small mb-0">Revisions</p>
                </div>
              </div>
              <p className="fw-semibold ms-4 text-dark fs-6">{BiddingData?.biddingDetails?.revisions}</p>
            </div>
          </div>
        </div>

        {/* Second Detail Section */}
        <div className="row w-98 mb-2 shadow-sm">

        <div className="col-md-6">
            <div className="d-flex flex-column mt-3">
              <div className="d-flex align-items-center ms-2">
                <div className="me-2" style={{ width: '20px', height: '20px' }}>
                  <img src={experience} alt="experience" className="img-fluid" />
                </div>
                <div>
                  <p className="text-secondary small mb-0">Experience</p>
                </div>
              </div>
              <p className="fw-semibold ms-4 text-dark fs-6">{BiddingData?.partnerDetails?.experience} Years</p>
            </div>
          </div>
          
          <div className="col-md-6">
            <div className="d-flex flex-column mt-3">
              <div className="d-flex align-items-center ms-2">
                <div className="me-2" style={{ width: '20px', height: '20px' }}>
                  <img src={timeline} alt="timeline" className="img-fluid" />
                </div>
                <div>
                  <p className="text-secondary small mb-0">Timeline</p>
                </div>
              </div>
              <p className="fw-semibold ms-4 text-dark fs-6">{BiddingData?.biddingDetails?.timeline}</p>
            </div>
          </div>
        </div>

        {/* Third Detail Section */}
        <div className="row w-98 mb-2 shadow-sm">
          <div className="col-md-6">
            <div className="d-flex flex-column mt-3">
              <div className="d-flex align-items-center ms-2">
                <div className="me-2" style={{ width: '20px', height: '20px' }}>
                  <img src={deliverySupport} alt="communication" className="img-fluid" />
                </div>
                <div>
                  <p className="text-secondary small mb-0">Post Delivery Support</p>
                </div>
              </div>
              <p className="fw-semibold ms-4 text-dark fs-6">{BiddingData?.biddingDetails?.postServiceSupport}</p>
            </div>
          </div>

          <div className="col-md-6">
            <div className="d-flex flex-column mt-3">
              <div className="d-flex align-items-center ms-2">
                <div className="me-2" style={{ width: '20px', height: '20px' }}>
                  <img src={technicalSupport} alt="money" className="img-fluid" />
                </div>
                <div>
                  <p className="text-secondary small mb-0">If yes</p>
                </div>
              </div>
              <div className="flex flex-col" style={{alignItems:"flex-start",textAlign:"left"}}>
                {BiddingData?.biddingDetails?.postServiceSupportFor.map((item,index)=>(
                  <p className="fw-semibold ms-4 text-dark fs-6" key={index}>{item}</p>
                ))}
              </div>
              
            </div>
          </div>
      
        </div>

        {/* Fourth Detail Section */}
        <div className="row w-98 mb-2 shadow-sm">
          <div className="col-md-6">
            <div className="d-flex flex-column mt-3">
              <div className="d-flex align-items-center ms-2">
                <div className="me-2" style={{ width: '20px', height: '20px' }}>
                  <img src={StartDate} alt="service" className="img-fluid" />
                </div>
                <div>
                  <p className="text-secondary small mb-0">Start Date</p>
                </div>
              </div>
              <p className="fw-semibold ms-4 text-dark fs-6">{BiddingData?.biddingDetails?.startDate}</p>
            </div>
          </div>

          <div className="col-md-6">
            <div className="d-flex flex-column mt-3">
              <div className="d-flex align-items-center ms-2">
                <div className="me-2" style={{ width: '20px', height: '20px' }}>
                  <img src={Payment} alt="communication" className="img-fluid" />
                </div>
                <div>
                  <p className="text-secondary small mb-0">Payment Structure</p>
                </div>
              </div>
              <p className="fw-semibold ms-4 text-dark fs-6">{BiddingData?.biddingDetails?.paymentRatio}</p>
            </div>
          </div>
        </div>

        <div className="row w-98 mb-2 shadow-sm">
        <div className="col-md-6">
            <div className="d-flex flex-column mt-3">
              <div className="d-flex align-items-center ms-2">
                <div className="me-2" style={{ width: '20px', height: '20px' }}>
                  <img src={timeline} alt="timeline" className="img-fluid" />
                </div>
                <div>
                  <p className="text-secondary small mb-0">Post Service Timeline</p>
                </div>
              </div>
              <p className="fw-semibold ms-4 text-dark fs-6">{BiddingData?.biddingDetails?.postServiceTimeline}</p>
            </div>
          </div>

          <div className="col-md-6">
          <div className="d-flex align-items-center ms-2 mb-3">
            <div className="me-2" style={{ width: '20px', height: '20px' }}>
              <img src={Star} alt="newFeature" className="img-fluid" />
            </div>
            <p className="fw-semibold text-dark mb-0 fs-6">Key Features</p>
          </div>
          <div className="flex flex-col" style={{alignItems:"flex-start"}}>
          {BiddingData?.biddingDetails?.keyFeatures.map((feature, index) => (
                <p className="fw-semibold ms-4 text-dark fs-6" key={index}>{feature}</p>
            ))}
          </div>
        </div>

        </div>
        
        

        {/* Logo Design Specialization Section */}
      </div>
      <div className="d-flex flex-column">
        <h4 className="mt-3 ms-2">Service Details</h4>

        <div className="row w-98 mb-2 shadow-sm">
          <div className="col-md-6">
            <div className="d-flex flex-column mt-4">
              <div className="d-flex align-items-center ms-2">
                <div className="me-2" style={{ width: '20px', height: '20px' }}>
                  <img src={UsedTech} alt="money" className="img-fluid" />
                </div>
                <div>
                  <p className="text-secondary small mb-0">Used Tech</p>
                </div>
              </div>
              <p className="fw-semibold ms-4 text-dark fs-6">{BiddingData?.biddingDetails?.usedTech}</p>
            </div>
          </div>
          
          <div className="col-md-6">
            <div className="d-flex flex-column mt-4">
              <div className="d-flex align-items-center ms-2">
                <div className="me-2" style={{ width: '20px', height: '20px' }}>
                  <img src={numberBlocks} alt="calendar" className="img-fluid" />
                </div>
                <div>
                  <p className="text-secondary small mb-0">No. of pages</p>
                </div>
              </div>
              <p className="fw-semibold ms-4 text-dark fs-6">{BiddingData?.biddingDetails?.Pages}</p>
            </div>
          </div>
        </div>

        <div className="row w-98 mb-2 shadow-sm">
          <div className="col-md-6">
            <div className="d-flex flex-column mt-4">
              <div className="d-flex align-items-center ms-2">
                <div className="me-2" style={{ width: '20px', height: '20px' }}>
                  <img src={Website} alt="money" className="img-fluid" />
                </div>
                <div>
                  <p className="text-secondary small mb-0">Domain/Hosting</p>
                </div>
              </div>
              <p className="fw-semibold ms-4 text-dark fs-6">{BiddingData?.biddingDetails?.hostingIncluded}</p>
            </div>
          </div>
          
        </div>
        
        <div className="mt-4 mb-3">
          <div className="d-flex align-items-center ms-2 mb-3">
            <div className="me-2" style={{ width: '20px', height: '20px' }}>
              <img src={list} alt="newFeature" className="img-fluid" />
            </div>
            <p className="fw-semibold text-dark mb-0 fs-6">All Features</p>
          </div>
          <div className="flex flex-col" style={{alignItems:"flex-start"}}>
            {Object.entries(BiddingData?.biddingDetails?.allFeatures).map(([category,items],index)=>(
              <div className="flex flex-col mb-[12px]" style={{alignItems:"flex-start"}}>
                  <p className="font-bold">{category}</p>
                  {items.map((item)=>(
                    <p>{item}</p>
                  ))}
              </div>
            ))}
        </div>
        </div>

      </div>
    </div>
  );
};


export const FAQSection = () => {
  const faqs = [
    {
      question: "What is the process for designing a logo?",
      answer: "Our process starts with understanding your brand and vision. You’ll fill out a brief, telling us about your preferences, target audience, and style. Based on your input, our designers will create initial concepts. You’ll have the opportunity to review these and provide feedback, leading to revisions until you’re happy with the final design. We’ll then deliver the logo in the formats you need (PNG, SVG, AI, etc.)."
    },
    {
      question: "What happens if I don’t like the logo, even after all the concepts and revisions?",
      answer: "We strive to ensure you’re satisfied with the design through multiple revisions. However, if you’re still not happy after the final revisions, you can opt for additional changes by paying a small extra fee. Alternatively, we offer a money-back guarantee or a discount on future services."
    },
    {
      question: "Why should I choose Bizowl for my logo design?",
      answer: "At Bizowl, we aggregate services from top-rated design partners, giving you access to a wide variety of styles and packages at competitive prices. We make it easy to compare offerings and find the perfect fit for your brand. Plus, we offer a free branding consultation to ensure your logo aligns with your overall brand strategy."
    },
    {
      question: "Can I get a refund if I’m not satisfied?",
      answer: "We offer a money-back guarantee if you are not satisfied with the final design. However, we encourage working with the designer through revisions to ensure your logo meets your expectations."
    },
    {
      question: "Can I provide examples or inspirations for my logo design?",
      answer: "Absolutely! We encourage you to share any examples, mood boards, or logos that inspire you. This helps our designers align their work with your vision."
    },
    {
      question: "What sets your designers apart?",
      answer: "We partner with experienced designers who specialize in various styles and industries. This means we can match you with a designer who understands your brand’s needs and delivers a custom, high-quality logo that stands out."
    },
    {
      question: "How do I communicate with the designer?",
      answer: "You’ll communicate with the designer via our platform, where you can easily provide feedback and track the progress of your logo. We also offer the option of scheduling calls for more in-depth discussions."
    },
    {
      question: "What is a free branding consultation, and how can I benefit from it?",
      answer: "Our free branding consultation helps you explore your brand’s identity, including color schemes, styles, and target audience, ensuring the logo aligns with your broader branding strategy. It’s a great way to get expert advice tailored to your business."
    }
  ];
  

  return (
    <div className="faq-container">
      <h4>Frequently Asked Questions</h4>
      <div className="faq-list">
        {faqs.map((faq, index) => (
          <div className="faq-item" key={index}>
            <details>
              <summary>{faq.question}</summary>
              <p>{faq.answer}</p>
            </details>
          </div>
        ))}
      </div>

      <style jsx>{`
        .faq-container {
          width: 90%;
          max-width: 1200px;
          margin: 40px auto;
          font-family: 'Cabin', sans-serif;
          text-align: left;
        }

        h4 {
          color: #1B2559;
          margin-bottom: 20px;
          font-weight: 600;
        }

        .faq-list {
          display: flex;
          flex-direction: column;
          gap: 12px;
        }

        .faq-item {
          background: white;
          border-radius: 8px;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        }

        details {
          padding: 0;
          width: 100%;
        }

        summary {
          padding: 16px 20px;
          cursor: pointer;
          position: relative;
          font-weight: 500;
          color: #1B2559;
          list-style: none;
          transition: background-color 0.3s ease;
        }

        summary::-webkit-details-marker {
          display: none;
        }

        summary::after {
          content: '+';
          position: absolute;
          right: 20px;
          transition: transform 0.3s ease;
          font-size: 20px;
          font-weight: 300;
        }

        details[open] summary::after {
          transform: rotate(45deg);
        }

        details[open] summary {
          background-color: #f8f9fa;
        }

        details p {
          padding: 16px 20px;
          margin: 0;
          background-color: #f8f9fa;
          color: #4a5568;
          border-top: 1px solid #edf2f7;
          line-height: 1.6;
        }

        summary:hover {
          background-color: #f8f9fa;
        }

        /* Animation for the content */
        details[open] p {
          animation: slideDown 0.3s ease-out;
        }

        @keyframes slideDown {
          from {
            opacity: 0;
            transform: translateY(-10px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }
      `}</style>
    </div>
  );
};
