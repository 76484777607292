import React, { useEffect, useState, useMemo } from "react";
// import PricingCards from "./PricingCard";
import useFormData from "./util/useFormData";
//assets
import BizowalLogo from "./assets/logo-of-BIZOWL--Business-Services.png";
import SalesSupport from "./assets/Sales-Support 1.png";
import practices from "./assets/privacy.png";
import Call from "./assets/call.png";

import PhonePic from "./assets/PhoneIcon.png";
import MessageImg from "./assets/Vector (2).png";
import PhoneImg from "./assets/Vector (3).png";
import VideoImg from "./assets/Vector (4).png";
import Star from "./assets/solar_star-bold.png";

import Filter from "./assets/filter.svg";
import DownArrowPic from "./assets/Vector (5).png";
import Growth from "./assets/BizowlPic/growth 1.png";
import Price from "./assets/BizowlPic/best-price 1.png";
import Support from "./assets/BizowlPic/service 1.png";
import Quality from "./assets/BizowlPic/quality-control 1.png";
import Compare from "./assets/BizowlPic/compare 1.png";
import Experience from "./assets/BizowlPic/customer-review 2.png";

import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

import Image1 from "./assets/BizowlPic/6895878 1.png";
import PopupModal from "./util/Popup";
import FilterComponent from "./util/FilterComponent";
import { MdArrowBackIos, MdKeyboardArrowLeft } from "react-icons/md";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Grid,
  Typography,
  FormControl,
  FormControlLabel,
  Radio,
  useMediaQuery,
  styled,
} from "@mui/material";
import "./serviceStyle.scss";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useParams } from "react-router-dom";
import { collection, getDocs,doc,query,where } from "firebase/firestore";
import { partnerDB } from "../../config/firebase";
import MobileFilters from "./LogoService/MobileFilters";
import FilterCard from "./LogoService/Filters";
import MobileViewCards from "./LogoService/MobileViewCards";
import mockData from "./LogoService/mockData.json";
import { Center, Flex } from "@chakra-ui/react";
import ChatPopup from "./ChatPopup";

const StyledFormControl = styled(FormControl)({
  display: "flex",
  flexDirection: "column", // Arrange items in a row
  alignItems: "start",
  color: "gray",
});

const StyledFormControlLabel = styled(FormControlLabel)({
  display: "flex",
  flexDirection: "row", // Arrange items in a row
  alignItems: "center",
  // Adjust spacing between radio and label
  fontSize: "5px",
});

const Service = () => {
  const navigate = useNavigate();
  const [IncomingData, setIncomingData] = useState(mockData);
  const [data, setData] = useState(mockData);
  //contains data for comparison
  const [compareData, setcompareData] = useState([]);
  const [partnerData,setPartnerData]=useState([]);
  const {enquiryID}=useParams();

  const { formData } = useFormData();
  const [filters, setFilters] = useState({
    priceRange: formData?.priceRange
      ? [formData.priceRange[0], formData.priceRange[1]]
      : [999, 10000],
    revisions: formData?.Revisions ? parseInt(formData.Revisions) : 1, // Ensure it's a number
    concepts: formData?.LogoConcepts ? parseInt(formData.LogoConcepts) : 1,
    TAT: formData?.deliveryTime ? parseInt(formData.deliveryTime) : "",
    PostDeliverySupport: "",
    AdditionalBrandingAssets: formData?.Assests || "",
    Deliverables: "",
  });

  useEffect(() => {
    handleReset();
  }, []);

  const [isVisible, setIsVisible] = useState(false);

  const [visibleItemId, setVisibleItemId] = useState(null);
  const [showMoreContent, setShowMoreContent] = useState(null);
  const [selectedServices, setSelectedServices] = useState([]);
  const [isChecked, setIsChecked] = useState({});
  const [checkboxState, setCheckboxState] = useState({});
  const [comparisonTrayVisible, setComparisonTrayVisible] = useState(false);
  const [EnquiryData,setEnquiryData]=useState();
  const [actualPartners, setActualPartners] = useState([]);
  const [comparisonPartners, setComparisonPartners] = useState([]);
  const {serviceName,enquiryId}=useParams();
  const [isLoading, setIsLoading] = useState(true); // New loading state
  // State to manage the anchor element for the menu

  // State to manage the selected index

  const [selectedIndex, setSelectedIndex] = useState(null);

  //for filters icon in mobile size
  const [showOverlay, setShowOverlay] = useState(false);
  const toggleOverlay = () => {
    setShowOverlay(!showOverlay);
  };

  const [openMenu, setOpenMenu] = useState(null);
  const menuOptions = ["Default", "Price:High to Low", "Price:Low to High"];

  const handleSliderChange = (newRange) => {
    setFilters((prevData) => ({
      ...prevData,
      priceRange: newRange,
    }));
  };


  const handleRevisionSliderChange = (newRange) => {
    setFilters((prevData) => ({
      ...prevData,
      revisions: newRange,
    }));
  };

  const handleConceptSliderChange = (newRange) => {
    setFilters((prevData) => ({
      ...prevData,
      concepts: newRange,
    }));
  };

  const handleFilter = (event) => {
    const { name, value } = event.target;
    setFilters((prevFilters) => {
      return {
        ...prevFilters,
        [name]: value, // Handle other filters normally
      };
    });
  };

  const handleReset = () => {
    setFilters({
      priceRange: [999, 100000],
      revisions: 1,
      concepts: 1,
      TAT: "",
      PostDeliverySupport: "",
      AdditionalBrandingAssets: "",
      Deliverables: "",
    });
  };



  const getData=async()=>{
    try{
      const collectionRef=collection(partnerDB,"biddingData");
      const enquiryQuery=query(collectionRef,where("EnquiryDetails.enquiryID","==",enquiryID));
      const querySnapshot=await getDocs(enquiryQuery);
      if(!querySnapshot.empty){
        const enquiryData=querySnapshot.docs[0].data();
        setEnquiryData(enquiryData)
        const docId=querySnapshot.docs[0].id;
        const partnerCollectionRef=collection(partnerDB,`biddingData/${docId}/partners`);
        const partnerDocs=await getDocs(partnerCollectionRef);
        const partnerArray=partnerDocs.docs.map((item)=>item.data());
        console.log("partnerArray:",partnerArray);
        setPartnerData(partnerArray);
      }else{
        console.log("data not found")
      }
    }catch(error){
      console.log(error.message);
    }
    
    
  }

  useEffect(()=>{
    getData();

  },[])

  // useEffect(()=>{
  //   const BusinessName=(partnerData[0]?.partnerDetails?.partnerBusinessName).substring(0,3).toUpperCase();
  //   console.log("BusinessName:",BusinessName);
  // },[partnerData]);

  // Partner Dummy Data
  const [ratings, setRatings] = useState(5);
  const colourScheme = [
    {
      letters: [
        {
          color: "#455A64",
        },
        {
          color: "#70A6E6",
        },
        {
          color: "#455A64",
        },
      ],
      bgColor: "#70A6E6",
      borderColor: "#70A6E6",
    },
    {
      letters: [
        {
          color: "#455A64",
        },
        {
          color: "#A0A3D8",
        },
        {
          color: "#455A64",
        },
      ],
      bgColor: "#A0A3D8",
      borderColor: "#A0A3D8",
    },
    {
      letters: [
        {
          color: "#455A64",
        },
        {
          color: "#0E3768",
        },
        {
          color: "#455A64",
        },
      ],
      bgColor: "#0E3768",
      borderColor: "#0E3768",
    },
    {
      letters: [
        {
          color: "#455A64",
        },
        {
          color: "#CF9DBB",
        },
        {
          color: "#455A64",
        },
      ],
      bgColor: "#FFBBE1",
      borderColor: "#FFBBE1",
    },
    {
      letters: [
        {
          color: "#455A64",
        },
        {
          color: "#A1D4C9",
        },
        {
          color: "#455A64",
        },
      ],
      bgColor: "#A1D4C9",
      borderColor: "#A1D4C9",
    },
    {
      letters: [
        {
          color: "#455A64",
        },
        {
          color: "#EAD39C",
        },
        {
          color: "#455A64",
        },
      ],
      bgColor: "#EAD39C",
      borderColor: "#EAD39C",
    },
  ];

  const applyFilters = (data, filters) => {
    return data
      .map((partner) => ({
        ...partner,
        Plans: partner.Plans.filter((plan) => {
          // Price Range Filter
          const isPriceInRange =
            plan.Price >= filters.priceRange[0] &&
            plan.Price <= filters.priceRange[1];
          if (!isPriceInRange) return false;

          // Revisions Filter
          let hasEnoughRevisions = true;
          if (filters.revisions > 0) {
            hasEnoughRevisions =
              plan["Revisions Included"] === "Unlimited" ||
              parseInt(plan["Revisions Included"]) >= filters.revisions;
          }
          if (!hasEnoughRevisions) return false;

          // Concepts Filter
          const hasEnoughConcepts =
            filters.concepts === 0 ||
            parseInt(plan.Concepts) >= filters.concepts;
          if (!hasEnoughConcepts) return false;

          // TAT (Turn Around Time) Filter
          const isTATInRange =
            filters.TAT === "" || plan["TAT (days)"] + " Days" === filters.TAT;
          if (!isTATInRange) return false;

          // Post Delivery Support Filter
          const hasCorrectPostDeliverySupport =
            !filters.PostDeliverySupport ||
            plan["Post Delivery Support"] === filters.PostDeliverySupport;
          if (!hasCorrectPostDeliverySupport) return false;

          // Additional Branding Assets Filter
          const areAllFilterAssetsInPlanAssets = (planAssets, filterAssets) => {
            const planAssetsSet = new Set(planAssets);
            return filterAssets.every((asset) => planAssetsSet.has(asset));
          };
          const planAssets = plan["Details of Assets"]
            .split(",")
            .map((asset) => asset.trim());

          const hasCorrectBrandingAssets =
            filters.AdditionalBrandingAssets.length === 0 ||
            areAllFilterAssetsInPlanAssets(
              planAssets,
              filters.AdditionalBrandingAssets
            );
          if (!hasCorrectBrandingAssets) return false;

          // Deliverables Filter
          const hasCorrectDeliverables =
            filters.Deliverables === "" ||
            plan.Deliverables.includes(filters.Deliverables);
          if (!hasCorrectDeliverables) return false;

          return true;
        }),
      }))
      .filter((partner) => partner.Plans.length > 0);
  };
  const filteredData = useMemo(
    () => applyFilters(IncomingData, filters),
    [IncomingData, filters]
  );

  useEffect(() => {
    setData(filteredData);
  }, [filteredData]);

  const displayItems = data;
  const displayCount = displayItems.length;
  const isMobile = useMediaQuery("(max-width:1000px)");

  // Open the menu
  const handleClick = (menuId) => {
    setOpenMenu((prevState) => (prevState === menuId ? null : menuId));
  };

  // sort by selection order
  const handleRadioChangeSort = (event) => {
    let selectedIndex = parseInt(event.target.value, 10);
    const sortedData = data.map((partner) => {
      return {
        ...partner,
        Plans: partner.Plans.sort((a, b) => {
          if (selectedIndex === 1) {
            // High to Low
            return b.Price - a.Price;
          } else if (selectedIndex === 2) {
            // Low to High
            return a.Price - b.Price;
          } else {
            // Default
            setData(IncomingData);
            return 0;
          }
        }),
      };
    });

    setData(sortedData);
  };

  const whyChooseBizowl = [
    {
      image: Growth,
      reason: "Business Growth Roadmap",
      alt: "Growth",
    },
    {
      image: Price,
      reason: "Best Price",
      alt: "BestPrice",
    },
    {
      image: Support,
      reason: "24*7 Support Assistance",
      alt: "Support",
    },
    {
      image: Quality,
      reason: "Best Quality, No disputes",
      alt: "Quality",
    },
    {
      image: Compare,
      reason: "Compare & Choose",
      alt: "Compare",
    },
    {
      image: Experience,
      reason: "Smoother Experience",
      alt: "Experience",
    },
  ];

  const adjustOpacity = (color, opacity) => {
    return color.replace(
      /rgba\((\d+),\s*(\d+),\s*(\d+),\s*(\d+)\)/,
      (match, r, g, b, a) => `rgba(${r}, ${g}, ${b}, ${opacity})`
    );
  };

  const darkenColor = (color, percent) => {
    return color.replace(
      /rgba\((\d+),\s*(\d+),\s*(\d+),\s*(\d+)\)/,
      (match, r, g, b, a) => {
        const factor = (100 - percent) / 100;
        const rDark = Math.floor(r * factor);
        const gDark = Math.floor(g * factor);
        const bDark = Math.floor(b * factor);
        return `rgba(${rDark}, ${gDark}, ${bDark}, ${a})`;
      }
    );
  };

  const colors = [
    "rgba(112, 166, 230,1)",
    "rgba(160, 163, 216,1)",
    "rgba(207, 157, 187, 1)",
    "rgba(234, 211, 156, 1)",
    "rgba(16isM1, 212, 201, 1)",
    "rgba(14, 55, 104, 1)",
  ];

  // Data Fetching
  useEffect(() => {
    const fetchPartners = async () => {
      setIsLoading(true);
      try {
        const querySnapshot = await getDocs(collection(partnerDB, "newPrData"));
        const getPartners = querySnapshot.docs.map((doc) => {
          const data = doc.data();
          const baseColor = colors[Math.floor(Math.random() * colors.length)];
          const bgColor = adjustOpacity(baseColor, 0.3); // Low opacity for bgColor
          const borderColor = adjustOpacity(baseColor, 1); // Higher opacity for borderColor
          const letterColor = adjustOpacity(baseColor, 1); // Same as borderColor for letters

          return {
            ...data,
            id: data.id,
            bgColor: bgColor,
            borderColor: borderColor,
          };
        });

        setActualPartners(getPartners);
        console.log("Actual Partners=>", getPartners);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching partners data:", error);
      }
    };

    fetchPartners();
  }, []);

  // ------------------------------------------------------------------------------------------------------------------

  // const findPlanById = (data, serviceId) => {
  //   for (const partner of data) {
  //     const plan = partner.Plans.find((p) => p.id === serviceId);
  //     if (plan) {
  //       return { partnerName: partner["Partner Name"], plan };
  //     }
  //   }
  //   return null;
  // };

  const removeCompareData=(index)=>{
    setcompareData((prevData)=>prevData.filter((item)=>item?.id!==index))
  }

  const handleCheckboxChange =(event,index)=>{
    const isChecked=event.target.checked;

    //partner data belonging to that specific index
    const partnerInfo=partnerData[index];

    const partnerObj={id:index,partnerName:partnerInfo?.partnerDetails?.partnerName,plan:partnerInfo}

    if(comparisonTrayVisible===false){
      setComparisonTrayVisible(true);
    }
 
    if(isChecked){
      setcompareData(((prevData)=>[...prevData,partnerObj]));
    }else{
      removeCompareData(index);
    }
    
    //add partners for comparison

    //add object with checkbox state for specific index
    // setCheckboxState((prevState) => ({
    //   ...prevState,
    //   [index]: isChecked,
    // }));

  };


  // Updated checkbox change handler
  // const handleCheckboxChange = (event, serviceId) => {
  //   const isChecking = event.target.checked;
  //   // Find the partner and plan that matches the serviceId
  //   const partnerInfo = findPlanById(data, serviceId);

  //   setComparisonPartners(partnerInfo);

  //   // Check if maximum selections reached
  //   if (
  //     isChecking &&
  //     (isMobile ? compareData.length >= 2 : compareData.length >= 3)
  //   ) {
  //     event.preventDefault();
  //     return;
  //   }

  //   // Update checked state
  //   setCheckboxState((prevState) => ({
  //     ...prevState,
  //     [serviceId]: isChecking,
  //   }));

  //   const { plan } = partnerInfo;

  //   // Update selected services
  //   if (isChecking) {
  //     setcompareData((prevData = []) => [...prevData, partnerInfo]);
  //     setComparisonTrayVisible(true);
  //   } else {
  //     setcompareData((prevData) =>
  //       prevData.filter((partner) => partner.plan.id !== serviceId)
  //     );
  //     if (compareData.length <= 1) {
  //       setComparisonTrayVisible(false);
  //     }
  //     setSelectedServices((prevServices) =>
  //       prevServices.filter((service) => service.id != serviceId)
  //     );
  //   }
  // };

  const handleIdFromCompareData = (serviceId) => {
    // Remove from selected services
    setCheckboxState((prevState) => {
      const newCheckboxState = { ...prevState };
      delete newCheckboxState[serviceId];
      return newCheckboxState;
    });
    setcompareData((prevData) => {
      const newCompareData = prevData.filter(
        (partner) => partner.plan.id !== serviceId
      );
      // Hide tray if no services left
      if (compareData.length <= 1) {
        setComparisonTrayVisible(false);
      }
      return newCompareData;
    });
  };

  // Updated comparison handler
  const handleCompare = () => {
    const queryString = compareData.map((item)=>item?.plan?.biddingDetails?.bidID).join(",");
    console.log("queryString:",queryString);
    //Include additional plan details in URL if needed
    // const detailsString = compareData
    //   .map(
    //     (service) =>
    //       `&${service.id}-details=${encodeURIComponent(
    //         JSON.stringify({
    //           partner: service.partnerName,
    //         })
    //       )}`
    //   )
    //   .join("");

    navigate(
      `/services/${serviceName}/quote-details/${enquiryID}/compare-plans/${queryString}`
    );
  };

  const handleCancel = () => {
    setComparisonTrayVisible(false);
    setcompareData([]);
    setCheckboxState({});
  };

  // click to open whatsapp in browser

  const handleClickWhatsApp = () => {
    const whatsappNumber = "919354651433";
    const whatsappURL = `https://wa.me/${whatsappNumber}`;
    window.open(whatsappURL, "_blank");
  };

  const handleMoreOption = (id) => {
    setVisibleItemId((prevId) => (prevId === id ? null : id));
    setShowMoreContent((prevId) => (prevId === id ? null : id));
  };

  if (isLoading) {
    return <p>Loading...</p>;
  }
  return (
    <div className="card relative">
      <div style={{ backgroundColor: "#c3ddf938", marginBottom: "2rem" }}>
        {isMobile ? (
          <div
            className="sticky top-0 left-0 w-screen bg-white  flex items-center justify-between py-3 px-2 md:p-3 "
            style={{
              boxShadow: "0 4px 15px 0 rgba(0, 0, 0, 0.25)", // Custom drop shadow
              zIndex: 300,
            }}
          >
            <div>
              {
                <Typography
                  variant="body2"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: ".85rem",
                    fontWeight: "600", // Adjust font size if needed
                  }}
                >
                  <span className="text-3xl">
                    <span className="">
                      <MdKeyboardArrowLeft />
                    </span>
                  </span>
                  Showing {displayCount} Plans
                </Typography>
              }
            </div>

            <div>
              <img src={BizowalLogo} alt="BizowlLogo" width="70px" />
            </div>
          </div>
        ) : (
          <div
            className="d-flex justify-content-between "
            style={{
              boxShadow: "0 4px 6.4px 0 #c6cac9",
              margin: "0px -15px 30px -15px",
              position: "fixed",
              top: "0",
              right: "0",
              left: "0",
              zIndex: "9999",
              backgroundColor: "#f7fcfb",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "90%",
                // marginTop:'8px',
                marginLeft: "4rem",
                backgroundColor: "#f7fcfb",
                paddingTop: "15px",
                paddingBottom: "15px",
              }}
            >
              <div
                className="d-flex align-items-center cursor-pointer"
                onClick={() => navigate("/")}
              >
                <img
                  src={BizowalLogo}
                  alt="BizowlLogo"
                  width={isMobile ? "120px" : "200px"}
                />
              </div>

              <div
                className="d-flex align-items-center gap-1 cursor-pointer"
                onClick={handleClickWhatsApp}
              >
                <img
                  src={PhonePic}
                  alt="PhonePic"
                  width={isMobile ? "30px" : "40px"}
                />
                <p>Need help?</p>
              </div>
            </div>
          </div>
        )}
        {!isMobile && (
          <div className="d-flex mt-24">
            <div className="m-5" style={{ width: "20rem" }}>
              <div>
                <div
                  className="card "
                  style={{
                    borderRadius: "25px",
                  }}
                >
                  <div
                    className="flex flex-col  align-items-center card-body"
                    style={{
                      boxShadow:
                        "0rem 0.25rem 0.25rem 0rem rgba(0, 0, 0, 0.25)",
                      borderRadius: `25px`,
                      cursor: "pointer",
                    }}
                    onClick={() => handleClick("sortby")}
                  >
                    <div
                      className="flex justify-between w-[100%] "
                      style={{
                        marginBottom: "",
                      }}
                    >
                      <div>Sort By</div>
                      <div
                        style={{
                          border: "1px solid",
                          borderColor: "white",
                          borderRadius: "100%",
                          boxShadow: "0rem 0.25rem 0.25rem 0rem #407BFF",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "1.5rem",
                          height: "1.5rem",
                        }}
                      >
                        <img
                          src={DownArrowPic}
                          alt="DownArrowPic"
                          width="28rem"
                          height="30rem"
                          style={{ padding: "0.3rem", borderRadius: "100%" }}
                        />
                      </div>
                    </div>

                    {openMenu === "sortby" && (
                      <div>
                        <StyledFormControl component="fieldset">
                          {menuOptions.map((option, index) => (
                            <StyledFormControlLabel
                              key={option}
                              control={
                                <Radio
                                  checked={index === selectedIndex}
                                  onChange={handleRadioChangeSort}
                                  value={index}
                                />
                              }
                              label={option}
                            />
                          ))}
                        </StyledFormControl>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* filterss ---------------------------------------------------------------------  */}
              <FilterCard
                setFilters={setFilters}
                filters={filters}
                handleFilter={handleFilter}
                handleSliderChange={handleSliderChange}
                handleRevisionSliderChange={handleRevisionSliderChange}
                handleConceptSliderChange={handleConceptSliderChange}
                handleReset={handleReset}
              />

              {/* do not share thing */}
              <div
                className="card"
                style={{
                  marginTop: "8rem",
                  borderRadius: "25px",
                  boxShadow: `-5px -4px 7px 0px rgba(121, 168, 224, 0.55),5px 4px 7px 0px rgba(121, 168, 224, 0.55)`,
                }}
              >
                <div
                  className="card-body"
                  style={{
                    backgroundColor: "#F4F8FF",
                    alignItems: "start",
                    justifyContent: "center",
                    borderRadius: "25px",
                    padding: "0px 8px",
                  }}
                >
                  <div className="flex items-start justify-start gap-2 mt-5 mb-2 pl-2 pr-2">
                    <img src={SalesSupport} width="90px" />
                    <p className="text-sm text-left text-[#1B2559]">
                      Our executives can help you select the best plan for your
                      need.
                    </p>
                  </div>
                  <div
                    className="flex gap-2 justify-center items-center bg-white "
                    style={{
                      maxWidth: "120px",
                      margin: "0 auto 40px auto",
                      borderRadius: "10px",
                      cursor: "pointer",
                    }}
                    onClick={handleClickWhatsApp}
                  >
                    <img
                      src={Call}
                      width="20px"
                      style={{
                        marginTop: "10px",
                      }}
                    />
                    <p className="mt-2 text-[#1C6ED0] text-sm ">Talk to Us</p>
                  </div>
                </div>
              </div>
              {/* why choose bizowl */}
              <div
                className="card"
                style={{
                  marginTop: "8rem",
                  backgroundColor: "#F6F6FD",
                  borderRadius: "25px",
                  fontFamily: "Cabin",
                  boxShadow:
                    "0.3125rem 0.25rem 0.4375rem 0rem rgba(121, 168, 224, 0.65)",
                }}
              >
                <div
                  className="card-body"
                  style={{
                    borderRadius: "25px",
                  }}
                >
                  <h3
                    style={{
                      fontSize: "1.4rem",
                      fontWeight: "bold",
                      textAlign: "left",
                      color: "#1B2559",
                      marginBottom: "2rem",
                    }}
                  >
                    Why Choose <span className="text-[#407BFF]">Bizowl</span>?
                  </h3>
                  {whyChooseBizowl?.map((item, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "start",
                        alignItems: "end",

                        marginTop: "0.5rem",
                        marginBottom: "1rem",
                      }}
                    >
                      <img alt="" src={item?.image} width="30px" />
                      <h6
                        style={{
                          marginLeft: "1rem",
                          color: "#1B2559",
                          textAlign: "left",
                        }}
                      >
                        {item?.reason}
                      </h6>
                    </div>
                  ))}
                </div>
              </div>
              {/* Best Practices  */}
              <div
                className="card-body card"
                style={{
                  paddingBottom: "0px",
                  marginTop: "8rem",
                  backgroundColor: "#F6F6FD",
                  borderRadius: "25px",
                  fontFamily: "Cabin",
                  color: "#1B2559",
                  boxShadow:
                    "0.3125rem 0.25rem 0.4375rem 0rem rgba(121, 168, 224, 0.65)",
                }}
              >
                <h3 className="text-2xl text-center font-bold">
                  Best Practices
                </h3>
                <div className="w-10/12 mt-4">
                  <p className="ml-6">
                    Do not share your number with your business partner.
                  </p>
                </div>
                <img src={practices} className="ml-auto size-[6rem]" />
              </div>
            </div>

            {/* Main Card  */}
            <div
              className="relative"
              style={{
                marginBottom: comparisonTrayVisible ? "100px" : "0px",
                width: "60%",
                transition: "margin-bottom 0.5s ease-in-out ",
              }}
            >
              {partnerData.map((item, index) => (
                <div key={index} className="m-5 w-full d-flex">
                  <div
                    key={index}
                    className="card shrink-0 flex flex-col justify-center relative"
                    style={{
                      height: isMobile ? "6rem" : "10rem",
                      width: isMobile ? "9rem" : "12rem",
                      backgroundColor: `${
                        colourScheme[index % 6].borderColor
                      }20`,
                      border: "2px solid white",
                      borderRadius: "1rem",
                      borderTopRightRadius: "0px",
                      borderBottomRightRadius: "0px",
                    }}
                  >
                    {console.log(item)}
                    
                    <div className="flex align-center w-full justify-center flex-col">
                      <div
                        className="d-flex justify-content-around"
                        style={{
                          border: "3px solid",
                          width: "60%",
                          borderColor: colourScheme[index % 6].borderColor,
                          borderRadius: "1rem",
                          padding: ".3rem",
                          fontSize: "large",
                          backgroundColor: "#F7FCFB",
                          marginTop: ".55rem",
                        }}
                      >
                        {item?.partnerDetails?.partnerName
                          .substring(0, 3)
                          .split("")
                          .map((letter, index2) => (
                            <span
                              style={{
                                fontWeight: "bold",
                                color:
                                  colourScheme[index % 6].letters[index2].color,
                              }}
                              key={index2}
                            >
                              {letter.toUpperCase()}
                            </span>
                          ))}
                      </div>
                      <p
                        style={{
                          color: "#407BFF",
                          marginTop: ".3rem",
                          fontSize: "small",
                        }}
                      >
                        Portfolio
                      </p>
                    </div>
                  </div>
                  {/* cards  */}
                  <div
                    style={{
                      width: "100%",
                      marginBottom: "2rem",
                      borderRadius: "1rem",
                      boxShadow: "0.20rem 0px .2rem 0px #407BFF6B",
                      overflow: "hidden",
                    }}
                  >
                    <div
                      className="bg-white "
                      style={{
                        width: isMobile ? "14rem" : "100%",
                        color: "#37474F",
                      }}
                    >
                      <div className="card-body d-flex justify-content-between w-full">
                        <div className="d-flex flex-column gap-2 text-[#455A64] align-items-start">
                          <h5
                            style={{
                              fontWeight: "bold",
                              fontSize: isMobile ? "1rem" : "1.5rem",
                              border: "none",
                            }}
                          >
                            Features
                          </h5>
                          {item?.biddingDetails.keyFeatures.map(
                            (element, index) => {
                              return (
                                <p
                                  style={{ fontSize: isMobile && "0.8rem" }}
                                  key={index}
                                >
                                  {element}
                                </p>
                              );
                            }
                          )}
                        </div>
                        <div
                          className="d-flex flex-column gap-4 text-start"
                          style={{ fontSize: "0.9rem" }}
                        >
                          <div>
                            <h6>Timeline</h6>
                            <p style={{ fontWeight: "bold" }}>
                              {item?.biddingDetails?.timeline}
                            </p>
                          </div>
                          <div>
                            <h6>Start Date</h6>
                            <p style={{ fontWeight: "bold" }}>
                              {item?.biddingDetails?.startDate}
                            </p>
                          </div>
                          <div>
                            <h6>Payment Ratio</h6>
                            <p style={{ fontWeight: "bold" }}>
                              {item?.biddingDetails?.paymentRatio}
                            </p>
                          </div>
                        </div>
                        <div className="flex flex-col gap-[.7rem">
                          <div className="tracking-tighter text-xs">
                            <h6>Total Price Included GST</h6>
                            <p className="font-bold text-xl my-1">
                              ₹ {item?.biddingDetails?.price}
                            </p>
                          </div>
                          <button
                            className="btn btn-primary w-fit rounded-[.6rem] py-1 btn-sm"
                            onClick={() =>
                              navigate(
                                `/services/${serviceName}/quote-details/${enquiryID}/getStarted/${item?.biddingDetails?.bidID}`
                              )
                            }
                            // onClick={() =>
                            //   navigate(
                            //     `/services/web-devlopement/quote-details/service-summary/?id=${item?.partnerDetails?.partnerId}`
                            //   )
                            // }
                          >
                            Get Started
                          </button>
                          <div style={{ marginTop: "1rem" }}>
                            <p className="tracking-tighter text-xs">
                              Communicate
                            </p>
                            <div
                              className="d-flex gap-2 justify-content-evenly"
                              style={{ fontSize: "small", width: "8rem" }}
                            >
                              <button
                                className="btn btn-primary rounded-[.6rem] btn-sm"
                                onClick={() => setIsVisible(true)}
                              >
                                <img
                                  src={MessageImg}
                                  alt="MessageImg"
                                  width={isMobile ? "14rem" : "20rem"}
                                />
                              </button>
                              <button className="btn btn-primary rounded-[.6rem] btn-sm">
                                <img
                                  src={PhoneImg}
                                  alt="PhoneImg"
                                  width={isMobile ? "14rem" : "20rem"}
                                  // height="20rem"
                                />
                              </button>
                              <button className="btn btn-primary rounded-[.6rem] btn-sm">
                                <img
                                  src={VideoImg}
                                  alt="VideoImg"
                                  width={isMobile ? "14rem" : "20rem"}
                                  // height="20rem"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div
                        className="d-flex justify-between px-3 py-1"
                        style={{ backgroundColor: "#F1FFFC " }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Checkbox
                            checked={compareData.some((item)=>item?.id===index)}
                            onChange={(event) =>
                              handleCheckboxChange(event, index)
                            }
                            disabled={
                              compareData.length >= 3 && !item.isChecked
                            }
                          />
                          <Typography>Add To Compare</Typography>
                        </div>
                        <div className="d-flex flex-column align-items-start">
                          <h6>Total Experience</h6>
                          <p style={{ fontWeight: "bold" }}>5 Years</p>
                        </div>
                        <div className="d-flex flex-column align-items-start">
                          <h6>Ratings</h6>
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            {Array.from({ length: ratings }, (_, index) => (
                              <img
                                key={index}
                                src={Star}
                                alt="Star"
                                width="20rem"
                              />
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* {isVisible && <ChatPopup setIsVisible={()=>setIsVisible(false)}/>} */}
                </div>
              ))}
              <div className="absolute top-52 w-1/2 left-20">
                {isVisible && !isMobile && (
                  <ChatPopup
                    setIsVisible={() => setIsVisible(false)}
                    row={"6"}
                  />
                )}
              </div>
            </div>
          </div>
        )}

        {/* Filter For Mobile  */}
        {isMobile && (
          <div
            className="flex justify-between "
            style={{
              marginTop: "20px",
              marginLeft: "3%",
            }}
          >
            {/* Sort BY */}
            <div>
              <Typography
                variant="body1"
                sx={{
                  border: "2px solid",
                  borderColor: `${
                    selectedIndex != null ? "#a3c2f7" : "#e5e7eb"
                  }`,
                  borderRadius: "100px",
                  padding: ".2rem 0.5rem",
                  backgroundColor: "white",
                  fontWeight: "400",
                  display: "flex",
                  alignItems: "center",
                  boxShadow: `${
                    selectedIndex != null
                      ? "0 1px 2px rgba(0, 102, 255, 0.2)"
                      : "0 1px 2px rgba(0, 0, 0, 0.05)"
                  }`,
                  cursor: "pointer",
                  transition: "all 0.3s ease",
                  position: "relative",
                }}
                onClick={() => handleClick("sortby")}
              >
                Sort By
                <span style={{ marginLeft: "0.5rem" }}>
                  {Boolean(openMenu) ? <IoIosArrowUp /> : <IoIosArrowDown />}
                </span>
                {openMenu === "sortby" && (
                  <div className="absolute flex  z-2">
                    <StyledFormControl
                      component="fieldset"
                      style={{
                        minWidth: "170px",
                        minHeight: "180px",
                        backgroundColor: "white",
                        borderRadius: "0px 20px 20px 20px",
                        marginTop: "13rem",
                        paddingLeft: "20px",
                        paddingTop: "20px",
                        marginLeft: "-15px",
                      }}
                    >
                      {menuOptions.map((option, index) => (
                        <StyledFormControlLabel
                          key={option}
                          control={
                            <Radio
                              checked={index === selectedIndex}
                              onChange={handleRadioChangeSort}
                              value={index}
                            />
                          }
                          label={option}
                          className="text-left"
                        />
                      ))}
                    </StyledFormControl>
                  </div>
                )}
              </Typography>
            </div>

            <div>
              <div
                style={{
                  border: "2px solid #e5e7eb",
                  padding: ".2rem .7rem",
                  borderTopLeftRadius: "35%",
                  borderBottomLeftRadius: "35%",
                  marginRight: "-2px",
                  backgroundColor: "white",
                  cursor: "pointer",
                }}
                onClick={toggleOverlay}
              >
                <img src={Filter} alt="Filter" className="size-6" />
              </div>
              <MobileFilters
                showOverlay={showOverlay}
                toggleOverlay={toggleOverlay}
                filters={filters}
                setFilters={setFilters}
                handleFilter={handleFilter}
                handleReset={handleReset}
                handleClick={handleClick}
                openMenu={openMenu}
                handleSliderChange={handleSliderChange}
                handleRevisionSliderChange={handleRevisionSliderChange}
                handleConceptSliderChange={handleConceptSliderChange}
              />
            </div>
          </div>
        )}

        {comparisonTrayVisible && (
          <div className="comparison-tray">
            <ul
              style={{
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: isMobile ? "100%" : "70%",
              }}
            >
              {compareData
                .map((service,index) => (
                  <li
                    style={{
                      listStyle: "none",
                      padding: !isMobile && "5px",
                      flexBasis: "auto",
                      flexGrow: 1,
                      margin: "10px",
                      borderRadius: "10px",
                      boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
                      display: "flex", // Ensure the li itself is a flex container
                      flexDirection: "column", // Stack children vertically
                      alignItems: "center", // Center-align children horizontally
                      textAlign: "center",
                      position: "relative",
                    }}
                    key={index}
                  >
                    <CloseIcon
                      className="close-icon"
                      onClick={() => removeCompareData(service.id)}
                      style={{
                        position: "absolute",
                        top: "5px",
                        right: "5px",
                        cursor: "pointer",
                      }}
                    />
                    <div
                      className="d-flex"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        height: !isMobile && "100px",
                      }}
                    >
                      <div
                        className="card"
                        style={{
                          height: isMobile ? "6rem" : "2rem",
                          width: isMobile ? "3rem" : "12rem",
                          border: "0.2rem solid",
                          borderColor: "white",
                        }}
                      >
                        <div
                          className="card-body"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <div
                            className={
                              isMobile
                                ? "d-flex justify-content-center"
                                : "d-flex justify-content-between"
                            }
                            style={{
                              border: "2px solid",
                              borderColor: service.borderColor,
                              borderRadius: "0.5rem",
                              padding: "0.5rem",
                              width: !isMobile && "100%",
                              height: "50px",
                              fontSize: "large",
                              backgroundColor: "#F7FCFB",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {service?.partnerName.substring(0,3).split("").map((letter, index2) => (
                                  <Box key={index2}>
                                  <span
                                  style={{
                                    fontWeight: "bold",
                                    color:colourScheme[((service?.id)%6)].letters[index2].color
                              }}
                              key={index2}
                            >
                              {letter.toUpperCase()}
                            </span>
                    </Box>
                  ))}
                            
                          </div>
                          <p style={{ color: "#407BFF", fontSize: "small" }}>
                            Portfolio
                          </p>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              {[...Array((isMobile?2:3) - compareData.length)].map((_, index) => (
                <li
                  key={`placeholder-${index}`}
                  style={{
                    listStyle: "none",
                    padding: "5px",
                    flexBasis: "auto",
                    flexGrow: 1,
                    margin: "10px",
                    borderRadius: "10px",
                    // boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
                    border: "1px solid #407bff", // Add a border
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: isMobile ? "6rem" : "7rem",
                  }}
                >
                  <Typography variant="body2" color="#407bff">
                    Add a Plan
                  </Typography>
                </li>
              ))}
            </ul>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: !isMobile && "30%",
                padding: !isMobile && "0 3rem 0 3rem",
              }}
            >
              <Button
                variant="contained"
                onClick={handleCompare}
                style={{ flex: 1, margin: "10px" }}
              >
                Compare
              </Button>
              <Button
                variant="outlined"
                onClick={handleCancel}
                style={{ flex: 1, margin: "10px" }}
              >
                Cancel
              </Button>
            </div>
          </div>
        )}

        {/* main card mobile  */}
        {isMobile &&
          partnerData.map((item, index) => (
            <Flex
              key={index}
              fontFamily={"Cabin"}
              marginTop={"3rem"}
              borderRadius={"1.5rem"}
              style={{
                backgroundImage: `linear-gradient(to bottom right, ${
                  colourScheme[index % 6].bgColor
                }80, white 65%)`,
              }}
              className={`py-3 px-3 overflow-hidden gap-1 text-[.7rem] mx-3 shadow-md`}
            >
              <Flex className="flex-col text-left gap-2 leading-5">
                <Flex
                  borderColor={colourScheme[index % 6].borderColor}
                  className="border-[5px] bg-white justify-center gap-2 py-1.5 text-2xl font-extrabold rounded-[.850rem] w-9/12"
                >
                  {item?.partnerDetails?.partnerName
                    .substring(0, 3)
                    .split("")
                    .map((letter, index2) => (
                      <Box key={index2}>
                        <span
                          style={{
                            fontWeight: "bold",
                            color:
                              colourScheme[index % 6].letters[index2].color,
                          }}
                          key={index2}
                        >
                          {letter.toUpperCase()}
                        </span>
                      </Box>
                    ))}
                </Flex>
                <Box>
                  <p className="text-[#455A64]">Start Date</p>
                  <p className="font-bold text-[#37474F] text-[.9rem]">
                    {item?.biddingDetails?.startDate}
                  </p>
                </Box>
                <Box>
                  <p className="text-[#455A64]">Timeline</p>
                  <p className="font-bold text-[#37474F] text-[.9rem]">
                    {item?.biddingDetails?.timeline}
                  </p>
                </Box>
                <Box>
                  <p className="text-[#455A64]">Total Price Included GST</p>
                  <p className="font-bold text-[#37474F] text-[1rem]">
                    ₹ {item?.biddingDetails?.price}
                  </p>
                </Box>
              </Flex>
              <Flex className="items-center justify-center flex-col">
                {" "}
                <Box className="mb-10">
                  <p className="text-[#455A64]">Payment Ratio</p>
                  <p className="font-bold text-[#37474F] text-[.9rem] w-full text-start">
                    {item?.biddingDetails?.paymentRatio}
                  </p>
                </Box>
              </Flex>
              <Flex className="flex-col gap-3 ml-auto ">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color: "#455A64",
                  }}
                >
                  <Checkbox
                    sx={{
                      width: "1rem",
                      height: "1rem",
                    }}
                    checked={compareData.some((item)=>item?.id===index)}
                    onChange={(event) =>
                      handleCheckboxChange(event, index)
                    }
                    disabled={compareData.length >= 2 && !item.isChecked}
                  />
                  <p className="ml-2">Add To Compare</p>
                </div>
                <Flex className="mt-4" gap={"8px"}>
                  <button
                    className="btn btn-primary rounded-[.3rem] size-[2rem] shadow-xl btn-sm"
                    onClick={() => setIsVisible(true)}
                  >
                    <img
                      src={MessageImg}
                      alt="MessageImg"
                      className="size-[1rem]"
                    />
                  </button>
                  <button className="btn btn-primary rounded-[.3rem] size-[2rem] shadow-xl btn-sm">
                    <img
                      src={PhoneImg}
                      alt="PhoneImg"
                      className="size-[1rem]"
                    />
                  </button>
                  <button className="btn btn-primary rounded-[.3rem] size-[2rem] btn-sm">
                    <img
                      src={VideoImg}
                      alt="VideoImg"
                      className="size-[1rem]"
                    />
                  </button>
                </Flex>
                <Flex className="items-center ml-auto text-[#407BFF]">
                  <span className="mb-0.5">View all features</span>{" "}
                  <span className="transform -rotate-180 text-3xl">
                    <MdKeyboardArrowLeft />
                  </span>
                </Flex>
                <Box>
                  <button className="btn btn-primary mt-3 w-fit text-base rounded-[.4rem] py-1.5 px-3 btn-sm">
                    Get Started
                  </button>
                </Box>
              </Flex>
            </Flex>
          ))}
        {isVisible && isMobile && (
          <div className="absolute top-40 w-8/12 left-16">
            <ChatPopup setIsVisible={() => setIsVisible(false)} row={"10"} />
          </div>
        )}
      </div>
      <PopupModal />
    </div>
  );
};

export default Service;
